<template>
  <Page id="e404" anchor="e404">
    <div />
    <div id="e404-main" class="true-center">
      <h1>E404</h1>
      <div class="true-center">
        <p>Looks like this page doesn't exist.</p>
        <p>Click on the button below to be taken back.</p>
      </div>
      <Button value="Take Me Back" @click="navHome" />
    </div>
    <div />
  </Page>
</template>

<script>
import Button from "@/components/global/Button.vue";
import Page from "@/components/core/Page.vue";

export default {
  components: {
    Button,
    Page,
  },
  methods: {
    navHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
#e404-main {
  flex-direction: column;
  gap: 40px;
}

#e404-main > div {
  flex-direction: column;
}
</style>